import React, { ElementType, FC } from 'react';
import classNames from 'classnames';

import { IPropsBaseSection } from './models';
import './BaseSection.scss';
import { Container } from 'layout';
import { UmbracoColorRules } from 'components/atoms/ColorPicker/models';
import Element from 'components/helpers/Element';
import { setInlineColorStyle } from 'components/atoms/ColorPicker/helpers';

const BaseSection: FC<IPropsBaseSection> = ({
  data,
  children,
  withoutContainerRightPartDesktop,
  withoutContainerRightPartMobile,
  isFullWidth,
  className,
}) => {
  const options = data?.[0];
  const colorData = options?.bgColor?.[0]?.color;
  const label = colorData?.label;
  const horizonPaddingMobile = options?.horizonPaddingMobile;
  const wrapperTag = options?.wrapperTag || 'section';
  const conditionalInlineColor =
    colorData && setInlineColorStyle(colorData, UmbracoColorRules.backgroundColor);

  return (
    <Element
      data-testid="BaseSection"
      element={wrapperTag as ElementType}
      className={classNames('base-section', {
        [`base-section--bg-color-${label}`]: label,
        'base-section--bg-color-white': !label,
        [`base-section--padding-${horizonPaddingMobile}`]: horizonPaddingMobile,
        'base-section--container-right-desktop': withoutContainerRightPartDesktop,
        'base-section--container-right-mobile': withoutContainerRightPartMobile,
        [`${className}`]: Boolean(className),
      })}
      {...conditionalInlineColor}
    >
      {isFullWidth ? children : <Container>{children}</Container>}
    </Element>
  );
};

export default BaseSection;
