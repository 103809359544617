import React, { FC } from 'react';
import { graphql } from 'gatsby';
import PromoFormComponent from '@promo-platform/promo-form';

import Layout from 'layout/Layout';
import HeroBanner from 'components/sections/HeroBanner';
import HowItWorks from 'components/sections/HowItWorks';

import { IPropsSignUpPage } from './models';

import './SignUpPage.scss';

import '@promo-platform/styles';

const SignUpPage: FC<IPropsSignUpPage> = ({ data: { pageData }, pageContext }) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    heroBanner,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    promoFormId,
    promoFormURL,
    howItWorks,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="SignUpPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="signUp-page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      {promoFormId && promoFormURL ? (
        <PromoFormComponent id={promoFormId} url={promoFormURL} />
      ) : null}
      {howItWorks?.length ? <HowItWorks data={howItWorks} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String) {
    pageData: signUpPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentSignUpPage
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
  }
`;

export default SignUpPage;
